import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { LogoLink, NavLinks, NavLink as NavLinkBase } from "../headers/light.js";

const StyledHeader = styled(Header)`
  
`;

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;

const Container = tw.div`relative  `;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("https://photos.zillowstatic.com/fp/6c558a04c80e8c930c5b30374d4353bf-cc_ft_1536.webp");
  ${tw`bg-green-500 bg-cover bg-center xl:ml-24 h-40 lg:h-auto lg:w-1/2 lg:flex-1`}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-2xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none`;
const Paragraph = tw.p`max-w-xl my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose sm:items-center`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded hocus:outline-none focus:shadow-outline transition duration-300`}
  }
  .primaryAction {
    ${tw`bg-primary-500 text-gray-100 hover:bg-primary-700`}
  }
  .secondaryAction {
    ${tw`mt-4 sm:mt-0 sm:ml-4 bg-gray-300 text-gray-700 hover:bg-gray-400 hover:text-gray-800`}
  }
`;

const ActionsInput = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;


export default ({
  navLinks = [

  ],
  heading = (
    <>
      Want to find the best investment properties?
      <wbr />
       <br />
      <span tw="text-primary-500">Our AI automatically SCANS and RANKS all open listings</span>
      <br />
      <span tw="text-primary-500">So you don't have to...</span>
      <br />
   
    </>
  ),
  description = "",
  primaryActionUrl = "investments",
  primaryActionText = "Browse All Ranked Properties",
  secondaryActionUrl = "#",
  secondaryActionText = "Search Hotels"
}) => {
  return (
     
      <TwoColumn>
        <LeftColumn>
         
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
             
            {/* <ActionsInput>
              <input type="text" placeholder="Your E-mail Address" />
              <button>Get Started</button>
            </ActionsInput> */}
          </Content>
        </LeftColumn>
        <RightColumn></RightColumn>
      </TwoColumn>
   
  );
};
